/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import get from 'lodash/get'

import auth from 'utils/auth'

import { useTranslation } from 'react-i18next'
import useColumnsData from 'utils/useColumnsData'

import { Table } from 'components/Common/Table'

import { columns } from './columns'

const WEIGHT_TOLERANCE = 5

export const SecurityAnalysisTable = props => {
  const { t } = useTranslation()

  const conditionalRowProps = row => {
    const projectedWeight = get(row.original, 'projectedWeight')
    const weight = get(row.original, 'effectiveDateWeight')

    if (projectedWeight === 0 && weight !== 0) return { className: 'dangerTR' }
    if (weight === 0 && projectedWeight !== 0) return { className: 'successTR' }
  }

  const calcPrecision = (row, projectedWeightCol) => {
    const actualWeight = get(row.original, 'actualWeight')
    const projectedWeight = get(row.original, projectedWeightCol)
    return actualWeight && projectedWeight ? (projectedWeight / actualWeight) * 100 : 0
  }

  const conditionalCellProps = (row, cell) => {
    if (cell.column.id === 'precision' || cell.column.id === 'precisionByProjectedWeightAtTaseEffectiveDate') {
      const projectedWeightCol = cell.column.id === 'precision' ? 'projectedWeight' : 'projectedWeightAtTaseEffectiveDate'
      const precision = calcPrecision(row, projectedWeightCol)

      if (Math.abs(precision - 100) > WEIGHT_TOLERANCE) {
        return { className: 'warningCell' }
      }
    }
  }

  const isLowPrecision = (row, projectedWeightCol) => {
    const precision = calcPrecision(row, projectedWeightCol)
    return Math.abs(precision - 100) > WEIGHT_TOLERANCE
  }

  const legends = [
    { color: '#e4ffe1', label: t('equity.indexOverview.legends.enteringSecurities') },
    { color: '#ffe4e1', label: t('equity.indexOverview.legends.leaveingSecurities') },
    auth.isAdmin() && {
      color: '#e6b64a',
      label: t('equity.indexOverview.legends.lowPrecision'),
      filter: row => isLowPrecision(row, 'projectedWeight'),
    },
    auth.isAdmin() && {
      color: '#e6b64a',
      label: t('equity.indexOverview.legends.lowPrecisionNew'),
      filter: row => isLowPrecision(row, 'projectedWeightAtTaseEffectiveDate'),
    },
  ]

  const { selectedSecurity } = props
  const exportFileNameContext = selectedSecurity ? selectedSecurity.label : ''

  return (
    <Table
      withExportButton
      columns={columns(useColumnsData())}
      {...props}
      noDataMsg={t('equity.securityAnalysis.noData')}
      conditionalRowProps={conditionalRowProps}
      conditionalCellProps={conditionalCellProps}
      legends={legends}
      exportFileNameContext={exportFileNameContext}
      virtualized={true}
    />
  )
}
