import React, { useState } from 'react'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import urljoin from 'url-join'

import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { isEng } from '../../../../i18n'

import Button from 'react-bootstrap/Button'
import axiosApi from 'utils/axiosApi'
import { AlgoTypes, SecTypes } from 'utils/const'
import { AlgoActionsInfoWrapper, AlgoActionsWrapper } from '../style'

const tPrefix = 'dataManagement.algoManagementTable'

export const AlgoActionsCell = row => {
  const { t } = useTranslation()
  const [runAlgoBtnvariant, setRunAlgoBtnVariant] = useState('info')
  const [loadStatsBtnvariant, setLoadStatsBtnVariant] = useState('info')
  const latestAlgoCalc = get(row.row.original, 'latestAlgoCalc')
  const location = useLocation()

  const buildJobData = row => {
    const { category, updateType, effectiveDate, ...rest } = row.row.original
    let secType
    switch (category) {
      case 'Tel-Bond':
        secType = SecTypes.corpBond
        break
      case 'Gov-Bond':
        secType = SecTypes.govBond
        break
      case 'Equity':
        secType = SecTypes.stock
        break
      case 'All-Bond':
        secType = SecTypes.allBond
        break
      default:
        secType = 'undefined'
    }

    const rebalanceUpdateTypes = ['Monthly Rebalance', 'Semi Annual Rebalance']
    const algoType = rebalanceUpdateTypes.includes(updateType) ? AlgoTypes.parameters : AlgoTypes.components

    return {
      secType,
      algoType,
      taseEffectiveDate: effectiveDate,
      ...rest,
    }
  }

  const runAlgo = async () => {
    setRunAlgoBtnVariant('info')
    const res = await axiosApi.post('jobs/', {
      definition: 'RUN_ALGO',
      data: buildJobData(row),
    })

    if (get(res, 'data.ok')) {
      setRunAlgoBtnVariant('success')
    } else {
      setRunAlgoBtnVariant('danger')
    }
  }

  const loadStats = async () => {
    setLoadStatsBtnVariant('info')
    const res = await axiosApi.post('jobs/', {
      definition: 'ALGO_POST_PROCESS',
      data: { calcDesc: latestAlgoCalc, reScanExpectedChanges: true },
    })

    if (get(res, 'data.ok')) {
      setLoadStatsBtnVariant('success')
    } else {
      setLoadStatsBtnVariant('danger')
    }
  }

  const openRebalanceResults = () => {
    const url = urljoin(location.pathname, `?calcDescId=${get(latestAlgoCalc, '_id')}`)
    window.open(url, '_blank')
  }

  const showPrevRuns = () => {
    const url = urljoin(location.pathname, `?prevRuns=1&calcDescId=${get(latestAlgoCalc, '_id')}`)
    window.open(url, '_blank')
  }

  return (
    <AlgoActionsWrapper>
      <Button variant={runAlgoBtnvariant} size="sm" onClick={() => runAlgo()}>
        {t(`${tPrefix}.runAlgo`)}
      </Button>

      {latestAlgoCalc && (
        <>
          <AlgoActionsInfoWrapper dir={isEng() ? 'ltr' : 'rtl'}>
            <Button variant={loadStatsBtnvariant} size="sm" onClick={() => loadStats()}>
              {t(`${tPrefix}.loadStats`)}
            </Button>
          </AlgoActionsInfoWrapper>

          <AlgoActionsInfoWrapper dir={isEng() ? 'ltr' : 'rtl'}>
            <Button variant="secondary" size="sm" onClick={() => openRebalanceResults()}>
              {t(`${tPrefix}.moreInfo`)}
            </Button>
          </AlgoActionsInfoWrapper>

          <AlgoActionsInfoWrapper dir={isEng() ? 'ltr' : 'rtl'}>
            <Button variant="secondary" size="sm" onClick={() => showPrevRuns()}>
              {t(`${tPrefix}.prevRuns`)}
            </Button>
          </AlgoActionsInfoWrapper>
        </>
      )}
    </AlgoActionsWrapper>
  )
}

AlgoActionsCell.propTypes = {
  row: PropTypes.object.isRequired,
}
