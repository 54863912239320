import React from 'react'
import compact from 'lodash/compact'
import { isEng } from '../../../../i18n'
import dayjs from 'dayjs'

import getFormattedValue from 'utils/getFormattedValue'
import ComponentsRank from 'components/Common/Table/TableCell/ComponentRank'
import SecurityName from 'components/Common/Table/TableCell/SecurityName'
import { TableCell } from 'components/Common/Table/TableCell'
import { TableCellExtraContent } from 'components/Common/Table/TableCellExtraContent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { AlgoTypes, SecTypes } from 'utils/const'
import auth from 'utils/auth'

const tPrefix = 'common.table.colHeaders'

export const columns = ({ t, pathPrefix, secType, algoType, effectiveDate, taseEffectiveDate, recordDate }) => {
  const isEquity = secType === SecTypes.stock
  const isTelBond = secType === SecTypes.corpBond
  const isGovBond = secType === SecTypes.govBond
  const isAllBond = secType === SecTypes.allBond
  const isComponents = algoType === AlgoTypes.components
  const isRebalance = algoType === AlgoTypes.parameters
  const isRecordDatePassed = !dayjs(effectiveDate).isBefore(recordDate)
  const isTaseEffectiveDatePassed = !dayjs(effectiveDate).isBefore(taseEffectiveDate)

  const isNewMethodology = dayjs(taseEffectiveDate).isAfter('2023-11-01')

  return compact([
    !isGovBond && {
      id: 'projectedComponentsRank',
      Header: t(`${tPrefix}.ranking`),
      accessor: 'projectedComponentsRank',
      Cell: ComponentsRank,
      width: 80,
    },
    {
      id: 'securityName',
      Header: t('common.security'),
      accessor: row => {
        let name = isEng() ? row.securityName : row.securityNameHebrew
        if (row.fastTrack) {
          return `${name} ${t('equity.securityAnalysis.exceptions.fastTrack')}`
        } else return name
      },
      Cell: SecurityName,
      //-->example for filter implementation:
      //disableFilters: false,
      //Filter: DefaultColumnFilter,
      width: 205,
    },
    {
      id: 'securityId',
      Header: t('common.securityId'),
      accessor: 'securityId',
    },
    {
      id: 'projectedChange',
      Header: t(`${tPrefix}.projectedChange`),
      accessor: 'projectedFundsChange',
      cellFormat: {
        format: 'currency',
        greenRedValues: true,
      },
    },
    ((isTelBond && isRebalance) || isAllBond) && {
      id: 'projectedPayment',
      Header: t('equity.generalSecurity.projectedPayment'),
      accessor: 'projectedPayment',
      cellFormat: {
        format: 'currency',
      },
    },
    (isTelBond || isAllBond) && {
      id: 'baseMarketValue',
      Header: isRecordDatePassed
        ? t(`${tPrefix}.recordDateMarketValue`)
        : isComponents
        ? t(`${tPrefix}.baseMarketValue`)
        : t(`${tPrefix}.projectedRecordDateMarketValue`),
      accessor: 'baseMarketValue',
      cellFormat: { format: 'currency-long' },
      width: 170,
    },
    ((isTelBond && isRebalance) || isAllBond) && {
      id: 'taseEffectiveDateMarketValue',
      Header: isTaseEffectiveDatePassed ? t(`${tPrefix}.taseEffectiveDateMarketValue`) : t(`${tPrefix}.projectedTaseEffectiveDateMarketValue`),
      accessor: 'taseEffectiveDateMarketValue',
      cellFormat: { format: 'currency-long' },
      width: 170,
      coloredReferenceSelector: 'baseMarketValue',
    },
    (isEquity || isComponents) && {
      id: 'projectedComponentsMarketValue',
      Header: t(`${tPrefix}.projectedComponentsMarketValue`),
      accessor: 'projectedComponentsMarketValue',
      cellFormat: { format: 'currency-long' },
      //-->example for filter implementation.
      //Filter: SliderColumnFilter,
      //filter: shortendMoney,
      width: 170,
      coloredReferenceSelector: 'baseMarketValue',
    },
    {
      id: 'projectedWeight',
      Header: t(`${tPrefix}.projectedWeight`),
      accessor: 'projectedWeight',
      cellFormat: { format: '%*100.00' },
    },
    auth.isAdmin() &&
      isTaseEffectiveDatePassed && {
        id: 'actualWeight',
        Header: t(`${tPrefix}.actualWeight`),
        accessor: 'actualWeight',
        cellFormat: { format: '%*100.00' },
      },
    auth.isAdmin() &&
      isTaseEffectiveDatePassed && {
        id: 'precision',
        Header: t(`${tPrefix}.precision`),
        accessor: row => (row.actualWeight ? Math.abs(row.projectedWeight / row.actualWeight) * 100 : null),
        cellFormat: { format: '%' },
      },
    auth.isAdmin() &&
      isTaseEffectiveDatePassed && {
        id: 'projectedWeightAtTaseEffectiveDate',
        Header: t(`${tPrefix}.projectedWeightAtTaseEffectiveDate`),
        accessor: 'projectedWeightAtTaseEffectiveDate',
        cellFormat: { format: '%*100.00' },
      },
    auth.isAdmin() &&
      isTaseEffectiveDatePassed && {
        id: 'precisionByProjectedWeightAtTaseEffectiveDate',
        Header: t(`${tPrefix}.precisionByProjectedWeightAtTaseEffectiveDate`),
        accessor: row => (row.actualWeight ? Math.abs(row.projectedWeightAtTaseEffectiveDate / row.actualWeight) * 100 : null),
        cellFormat: { format: '%' },
      },
    isAllBond && {
      id: 'weightBeforeUpdate',
      Header: t(`${tPrefix}.weightBeforeUpdate`),
      accessor: 'weightBeforeUpdate',
      cellFormat: { format: '%*100.00' },
    },
    {
      id: 'weight',
      Header: isAllBond ? `${t(`${tPrefix}.currentWeight`)}` : t(`${tPrefix}.weightBeforeUpdate`),
      accessor: 'effectiveDateWeight',
      cellFormat: { format: '%*100.00' },
    },
    auth.isAdmin() &&
      isAllBond && {
        id: 'projectedMarketValue',
        Header: `${t(`${tPrefix}.projectedMarketValue`)} (Admin)`,
        accessor: 'projectedMarketValue',
        cellFormat: { format: 'currency' },
      },
    {
      id: 'projectedWeightFactor',
      Header: t(`${tPrefix}.projectedWeightFactor`),
      accessor: 'projectedWeightFactor',
      cellFormat: { format: 'float' },
    },
    {
      id: 'weightFactor',
      Header: t(`${tPrefix}.currentWeightFactor`),
      accessor: isEquity ? 'effectiveDateWeightFactor' : 'weightFactor',
      cellFormat: { format: 'float' },
    },
    isEquity && {
      id: 'freeFloatRate',
      Header: t(`${tPrefix}.freeFloatRate`),
      accessor: 'freeFloatRate',
      cellFormat: { format: '%' },
    },
    isEquity &&
      !isNewMethodology && {
        id: 'projectedIndexAdjustedFreeFloatRate',
        Header: t(`${tPrefix}.projectedIndexAdjustedFreeFloatRate`),
        accessor: 'projectedIndexAdjustedFreeFloatRate',
        Cell: row => {
          const { indexAdjustedFreeFloatRate, projectedIndexAdjustedFreeFloatRate } = row.row.original

          const popoverText = `${t('equity.totalRow.currently')} ${getFormattedValue({
            value: indexAdjustedFreeFloatRate,
            formatOverride: '%-shorter',
            toShort: true,
          })}`

          const diff = projectedIndexAdjustedFreeFloatRate - indexAdjustedFreeFloatRate
          let color = null
          if (diff !== 0) {
            if (diff > 0) {
              color = 'green'
            } else {
              color = 'red'
            }
          }

          return (
            <TableCell
              value={projectedIndexAdjustedFreeFloatRate}
              format="%-shorter"
              color={color}
              extraContent={<TableCellExtraContent popoverText={popoverText} content={<FontAwesomeIcon icon={faInfoCircle} />} />}
            />
          )
        },
      },
    isEquity &&
      isNewMethodology && {
        id: 'liquidationGroup',
        accessor: 'liquidationGroup',
        Cell: row => {
          const { liquidationCoefficient, liquidationGroup } = row.row.original
          return `${liquidationGroup} (${liquidationCoefficient})`
        },
        Header: columns => {
          const { turnoverStatsStartDate, turnoverStatsEndDate } = columns.data[0]
          if (!turnoverStatsStartDate || !turnoverStatsEndDate) return t(`${tPrefix}.liquidationGroup`)
          return (
            <div>
              {t(`${tPrefix}.liquidationGroup`)}
              <TableCellExtraContent
                popoverText={t(`${tPrefix}.liquidationGroupDetailed`, {
                  startDate: dayjs(turnoverStatsStartDate).format('DD/MM/YYYY'),
                  endDate: dayjs(turnoverStatsEndDate).format('DD/MM/YYYY'),
                })}
                content={<FontAwesomeIcon icon={faInfoCircle} />}
                key="liquidationGroup"
              />
            </div>
          )
        },
      },
    {
      id: 'projectedFundsMarketValue',
      Header: t(`${tPrefix}.projectedValueInFunds`),
      accessor: 'projectedFundsMarketValue',
      cellFormat: { format: 'currency' },
    },
    isAllBond && {
      id: 'currFundsMarketValueWithPayments',
      Header: t(`${tPrefix}.valueInFundsBeforeUpdate`),
      accessor: 'currFundsMarketValueWithPayments',
      cellFormat: { format: 'currency' },
    },

    !isAllBond && {
      id: 'currFundsMarketValue',
      Header: isAllBond ? `${t(`${tPrefix}.currentValueInFunds`)} (Admin)` : t(`${tPrefix}.valueInFundsBeforeUpdate`),
      accessor: 'currFundsMarketValue',
      cellFormat: { format: 'currency' },
    },
  ])
}
