import React from 'react'
import compact from 'lodash/compact'
import urljoin from 'url-join'
import dayjs from 'dayjs'

import { isEng } from '../../../../i18n'

import IssuerLimitBadge from 'components/Common/Table/TableCellExtraContent/IssuerLimitBadge'
import GradualUniverseFactorBadge from 'components/Common/Table/TableCellExtraContent/GradualUniverseFactorBadge'

import { TableCell } from 'components/Common/Table/TableCell'
import ComponentsRank from 'components/Common/Table/TableCell/ComponentRank'
import { AlgoTypes, SecTypes } from 'utils/const'
import auth from 'utils/auth'

const tPrefix = 'common.table.colHeaders'

export const columns = ({ t, pathPrefix, taseEffectiveDate, effectiveDate, algoType, secType, history }) => {
  const isEquity = secType === SecTypes.stock
  // const isTelBond = secType === SecTypes.corpBond
  // const isComponents = algoType  === AlgoTypes.components
  const isAllBonds = secType === SecTypes.allBond
  const isRebalance = algoType === AlgoTypes.parameters
  const isBondsRebalance = !isEquity && isRebalance

  const isTaseEffectiveDatePassed = !dayjs(effectiveDate).isBefore(taseEffectiveDate)

  return compact([
    {
      id: 'indexName',
      Header: t('common.index'),
      accessor: isEng() ? 'indexName' : 'indexNameHeb',
      Cell: row => {
        const note = row.row.original.fastTrack ? t('equity.securityAnalysis.exceptions.fastTrack') : undefined

        const linkToIndex = urljoin(`/${pathPrefix}/index/`, history.location.search, `&index=${row.row.original.indexId}`)

        return <TableCell noPopup link={linkToIndex} value={row.value} note={note} direction={isEng() ? 'ltr' : 'rtl'} />
      },
    },
    {
      id: 'projectedComponentsRank',
      Header: t(`${tPrefix}.ranking`),
      accessor: 'projectedComponentsRank',
      Cell: ComponentsRank,
    },
    {
      id: 'projectedChange',
      Header: t(`${tPrefix}.projectedChange`),
      accessor: 'projectedFundsChange',
      cellFormat: {
        format: 'currency',
        greenRedValues: true,
      },
    },
    (isBondsRebalance || isAllBonds) && {
      id: 'projectedPayment',
      Header: t('equity.generalSecurity.projectedPayment'),
      accessor: 'projectedPayment',
      cellFormat: { format: 'currency' },
    },
    {
      id: 'projectedWeight',
      Header: t(`${tPrefix}.projectedWeight`),
      accessor: 'projectedWeight',
      cellFormat: { format: '%*100.00' },
      Cell: row => <TableCell value={row.value} format={'%*100.00'} extraContent={compact([IssuerLimitBadge({ data: row.row.original })])} />,
    },
    auth.isAdmin() &&
      isTaseEffectiveDatePassed && {
        id: 'actualWeight',
        Header: t(`${tPrefix}.actualWeight`),
        accessor: 'actualWeight',
        cellFormat: { format: '%*100.00' },
      },
    auth.isAdmin() &&
      isTaseEffectiveDatePassed && {
        id: 'precision',
        Header: t(`${tPrefix}.precision`),
        accessor: row => (row.actualWeight ? Math.abs(row.projectedWeight / row.actualWeight) * 100 : null),
        cellFormat: { format: '%' },
      },
    auth.isAdmin() &&
      isTaseEffectiveDatePassed && {
        id: 'projectedWeightAtTaseEffectiveDate',
        Header: t(`${tPrefix}.projectedWeightAtTaseEffectiveDate`),
        accessor: 'projectedWeightAtTaseEffectiveDate',
        cellFormat: { format: '%*100.00' },
      },
    auth.isAdmin() &&
      isTaseEffectiveDatePassed && {
        id: 'precisionByProjectedWeightAtTaseEffectiveDate',
        Header: t(`${tPrefix}.precisionByProjectedWeightAtTaseEffectiveDate`),
        accessor: row => (row.actualWeight ? Math.abs(row.projectedWeightAtTaseEffectiveDate / row.actualWeight) * 100 : null),
        cellFormat: { format: '%' },
      },
    isAllBonds && {
      id: 'weightBeforeUpdate',
      Header: t(`${tPrefix}.weightBeforeUpdate`),
      accessor: 'weightBeforeUpdate',
      cellFormat: { format: '%*100.00' },
    },
    (!isAllBonds || auth.isAdmin()) && {
      id: 'weight',
      Header: isAllBonds ? `${t(`${tPrefix}.currentWeight`)} (Admin)` : t(`${tPrefix}.weightBeforeUpdate`),
      accessor: 'effectiveDateWeight',
      cellFormat: { format: '%*100.00' },
    },
    {
      id: 'projectedWeightFactor',
      Header: t(`${tPrefix}.projectedWeightFactor`),
      accessor: 'projectedWeightFactor',
      cellFormat: { format: 'float' },
      Cell: row => <TableCell value={row.value} format={'float'} extraContent={compact([GradualUniverseFactorBadge({ data: row.row.original })])} />,
    },
    {
      id: 'weightFactor',
      Header: t(`${tPrefix}.currentWeightFactor`),
      accessor: isEquity ? 'effectiveDateWeightFactor' : 'weightFactor',
      cellFormat: { format: 'float' },
    },
    {
      id: 'projectedFundsMarketValue',
      Header: t(`${tPrefix}.projectedValueInFunds`),
      accessor: 'projectedFundsMarketValue',
      cellFormat: { format: 'currency' },
    },
    isAllBonds && {
      id: 'currFundsMarketValueWithPayments',
      Header: t(`${tPrefix}.valueInFundsBeforeUpdate`),
      accessor: 'currFundsMarketValueWithPayments',
      cellFormat: { format: 'currency' },
    },
    (!isAllBonds || auth.isAdmin()) && {
      id: 'currFundsMarketValue',
      Header: isAllBonds ? `${t(`${tPrefix}.currentValueInFunds`)} (Admin)` : t(`${tPrefix}.valueInFundsBeforeUpdate`),
      accessor: 'currFundsMarketValue',
      cellFormat: { format: 'currency' },
    },
  ])
}
